/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$font-family-sans-serif: 'Sequel Sans', sans-serif;
$font-family-headings: 'wedding-gothic-atf', sans-serif;
$font-family-sequel-wide: 'Sequel 100 Wide', sans-serif;

/*  Colors  */
$body-bg: #111217;
$body-color: #ffffff;

$primary: #0585FF;

/*  Grid Breakpoints  */
$grid-breakpoints: (
	xxs: 0,
	xs: 375px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1280px
) !default;
