/* ------------------------------------------------------------ *\
	List Logos
\* ------------------------------------------------------------ */

.list-logos {
	list-style-type: none;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	padding: 0;
	margin: 0 -25px;

	li {
		padding: 0 25px;
	}

	a {
		opacity: .5;
		display: block;
		font-size: 0;
		text-decoration: none;
		transition: opacity .4s;

		&:hover {
			opacity: 1;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.list-logos {
		li {
			padding-bottom: 25px;
		}
	}
}
