/* ------------------------------------------------------------ *\
	Section Phones
\* ------------------------------------------------------------ */

.section-phones {
	margin: 290px 0 205px;
	text-align: center;

	&__head {
		display: flex;
		justify-content: center;
		margin: 0 -45px 80px;
	}
	&__head__inner {
		width: 33.3333%;
	}
	&__head__entry {
		font-size: 18px;
		font-weight: 400;
		line-height: 1.25;
	}

	&__title {
		margin-bottom: 20px;
	}

	&__body {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -45px;
	}

	&__col {
		width: 33.3333%;
		padding: 0 45px;

		&:nth-child(3n + 1) {
			margin-top: -210px;
		}
		&:nth-child(3n + 3) {
			margin-top: -330px;
		}
	}

	&__entry {
		max-width: 250px;
		margin: 0 auto;
		font-size: 16px;
		line-height: 1.25;
	}
	&__entry__title {
		margin-bottom: 20px;
		font-weight: 500;

		strong {
			font-weight: 700;
		}
	}

	.phone {
		margin-bottom: 60px;
	}
}


/* === Tablet === */
@include media-breakpoint-down(md) {
	.section-phones {
		margin: 100px 0;
		text-align: left;

		&__head__inner {
			width: 100%;
			text-align: center;
		}

		&__col {
			display: flex;
			align-items: center;
			width: 100%;
			margin-bottom: 100px;

			&:nth-child(3n + 1) {
				margin-top: 0;
			}
			&:nth-child(3n + 2) {
				flex-direction: row-reverse;
			}
			&:nth-child(3n + 3) {
				margin-top: 0;
			}
		}

		&__entry {
			margin: 0 auto;
		}

		.phone {
			flex-shrink: 0;
			width: 50%;
			margin-bottom: 30px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.section-phones {
		text-align: center;

		&__head {
			margin-bottom: 30px;
		}
		&__head__entry {
			font-size: 16px;
		}

		&__entry {
			max-width: 300px;
		}

		&__col {
			display: block;
		}

		.phone {
			width: 60%;
		}
	}
}
