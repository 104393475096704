/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials {
	ul {
		list-style-type: none;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		margin: 0;
		font-size: 0;
	}

	li {
		padding: 0 10px;
	}

	a {
		display: inline-block;
		font-size: 0;
		color: inherit;
		text-decoration: none;
		transition: opacity .3s;

		&:hover {
			opacity: .7;
		}
	}
}
