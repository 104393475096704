/* ------------------------------------------------------------ *\
	Btn
\* ------------------------------------------------------------ */

.btn {
	/*  Contained in critical CSS in HEAD  */
	/*position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 9px 25px;
	border: 2px solid rgba(#fff, .1);
	border-radius: 8px;
	background: rgba(#fff, .1);
	font-family: $font-family-sans-serif;
	font-size: 16px;
	font-weight: 700;
	color: #fff;
	text-decoration: none;
	cursor: pointer;
	appearance: none;*/
	transition: background .3s;

	&:hover {
		background: rgba(#fff, .3);
	}

	&:focus {
		box-shadow: none;
	}

	svg {
		display: inline-block;
		vertical-align: middle;
		margin-left: 6px;
		margin-top: 2px;
	}

	&--small {
		padding: 6px 9px;
		border-radius: 4px;
		border-color: transparent;
		font-size: 12px;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.btn {
		padding: 6px 14px;
	}
}
