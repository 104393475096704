/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	/*  Contained in critical CSS in HEAD file  */
	/*&__inner {
		display: flex;
		justify-content: space-between;
		padding: 50px 50px 0;
	}*/
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.header {
		&__inner {
			align-items: center;
			padding: 30px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.header {
		&__inner {
			flex-direction: column;
			padding: 30px 15px 10px;
		}

		.logo {
			margin-bottom: 30px;
		}


		.btn {
			display: none;
		}
	}
}
