/* ------------------------------------------------------------ *\
	Text Image
\* ------------------------------------------------------------ */

.text-image {
	&__inner {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin: 0 -35px;
	}

	&__content {
		width: 56%;
		padding: 0 35px;
	}

	&__media {
		width: 44%;
		padding: 0 35px;
		text-align: right;
	}

	.phone {
		margin-right: 0;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.text-image {
		&__inner {
			margin: 0 -20px;
		}

		&__content {
			padding: 0 20px;
		}

		&__media {
			padding: 0 20px;
		}

		.phone {
			margin: 0 auto;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.text-image {
		&__inner {
			display: block;
		}

		&__media {
			width: 100%;
			margin-bottom: 30px;
		}

		&__content {
			width: 100%;
		}

		.phone {
			width: 70%;
		}
	}
}
