/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
	font-family: 'Sequel 100 Wide';
	src: url('../fonts/SequelSans-BoldHead.woff2') format('woff2'),
	url('../fonts/SequelSans-BoldHead.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Sequel 100 Wide';
	src: url('../fonts/SequelSans-MediumHead.woff2') format('woff2'),
	url('../fonts/SequelSans-MediumHead.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Sequel 100 Wide';
	src: url('../fonts/SequelSans-BookHead.woff2') format('woff2'),
	url('../fonts/SequelSans-BookHead.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Sequel Sans';
	src: url('../fonts/SequelSans-MediumBody.woff2') format('woff2'),
	url('../fonts/SequelSans-MediumBody.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Sequel Sans';
	src: url('../fonts/SequelSans-BoldBody.woff2') format('woff2'),
	url('../fonts/SequelSans-BoldBody.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Sequel Sans';
	src: url('../fonts/SequelSans-BookBody.woff2') format('woff2'),
	url('../fonts/SequelSans-BookBody.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
