/* ------------------------------------------------------------ *\
	Support
\* ------------------------------------------------------------ */

.support {
	margin: 150px 0;

	&__title {
		margin-bottom: 50px;
		font-family: $font-family-sequel-wide;
		font-weight: 500;
		text-align: center;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.support {
		margin: 100px 0;

		&__title {
			margin-bottom: 30px;
		}
	}
}
