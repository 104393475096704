/* ------------------------------------------------------------ *\
	Field
\* ------------------------------------------------------------ */

.field {
	width: 100%;
	height: 46px;
	padding: 0 14px;
	border: 1px solid rgba(#fff, .5);
	border-radius: 4px;
	outline: 0;
	margin: 0;
	background: transparent;
	box-shadow: none;
	font-family: $font-family-sans-serif;
	font-size: 16px;
	font-weight: 700;
	color: #fff;
	appearance: none;
	transition: border-color .3s;

	&::placeholder {
		color: rgba(#fff, .5);
	}

	&:focus {
		border-color: rgba(#fff, 1);
		box-shadow: none;
	}
}
