/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

.color-primary {
	color: $primary;
}

.d-none {
	display: none !important;
}
