/* ------------------------------------------------------------ *\
	Subscribe
\* ------------------------------------------------------------ */

.subscribe {
	margin: 150px 0;
	text-align: center;

	&__content {
		padding-top: 10px;
		margin: 0 auto;
	}

	&__icon {
		margin-bottom: 27px;
	}

	&__title {
		margin-bottom: 16px;
	}

	&__entry {
		padding-bottom: 50px;
		font-size: 18px;
	}

	&__form {
		max-width: 350px;
		margin: 0 auto;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.subscribe {
		margin: 100px 0;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.subscribe {
		&__entry {
			padding: 0 0 40px;
			font-size: 16px;

			br {
				display: none;
			}
		}
	}
}
