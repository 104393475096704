/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

html {
	background: $body-bg;
	font-family: $font-family-sans-serif;
	font-size: 16px;
	font-weight: 500;
	color: $body-color;
	line-height: 1.375;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 1rem;
	font-family: $font-family-headings;
	font-weight: 400;
	text-transform: uppercase;

	strong {
		font-weight: 600;
	}
}

h1 {
	font-size: 48px;
	line-height: 1.3056;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 24px;
}

h4 {
	font-size: 18px;
}

h5 {
	font-size: 16px;
}

h6 {
	font-size: 14px;
}

p {
	margin-bottom: 1.4em;

	&:last-child {
		margin-bottom: 0;
	}
}


/* === Mobile === */
@include media-breakpoint-down(sm) {
	h1 {
		font-size: 36px;
	}

	h2 {
		font-size: 28px;
	}

	h3 {
		font-size: 20px;
	}
}
