/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	padding-bottom: 40px;
	text-align: center;

	&__copyright {
		margin-top: 18px;

		a {
			text-decoration: none;
			color: inherit;
			transition: color .3s;

			&:hover {
				color: $primary;
			}
		}
	}

	.socials {
		margin: 30px 0;
	}
}
