/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */

.container {
	max-width: 968px;
	padding-left: 30px;
	padding-right: 30px;
	margin: 0 auto;

	&--medium {
		max-width: 1200px;
	}

	&--large {
		max-width: 1260px;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.container {
		padding-left: 15px;
		padding-right: 15px;
	}
}
