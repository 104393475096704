/* ------------------------------------------------------------ *\
	Boxes
\* ------------------------------------------------------------ */

.boxes {
	&__container {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
	}
}

/* ------------------------------------------------------------ *\
	Box
\* ------------------------------------------------------------ */

.box {
	width: 62%;
	padding: 10px;

	&:nth-child(4n + 3),
	&:nth-child(4n + 2) {
		width: 38%;
	}

	&__inner {
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		height: 450px;
		padding: 40px;
		border-radius: 18px;
		background: #20222F;
		transition: transform .3s;

		&:hover {
			transform: scale(1.02);
		}
	}

	&__title {
		margin-bottom: 4px;
		font-family: $font-family-sequel-wide;
		font-weight: 500;

		strong {
			font-weight: 700;
		}
	}

	&__entry {
		max-width: 150px;
		font-size: 14px;
		line-height: 1.25;
		opacity: .7;
	}

	&__container {
		flex: 1;
		padding: 10px 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__icon {
		display: inline-flex;
		vertical-align: middle;
		justify-content: center;
		align-items: center;
		width: 198px;
		height: 198px;
		border-radius: 50%;
		background: rgba(#fff, .3);
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.box {
		width: 50%;

		&:nth-child(4n + 3),
		&:nth-child(4n + 2) {
			width: 50%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.box {
		&__inner {
			height: 400px;
			padding-bottom: 0;
		}
	}
}

/* === Mobile Small === */
@include media-breakpoint-down(xs) {
	.box {
		width: 100%;

		&:nth-child(4n + 3),
		&:nth-child(4n + 2) {
			width: 100%;
		}
	}
}
