/* ------------------------------------------------------------ *\
	Features
\* ------------------------------------------------------------ */

.features {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -20px;
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.features {
		margin: 0 -10px;
	}
}

/* ------------------------------------------------------------ *\
	Feature
\* ------------------------------------------------------------ */

.feature {
	width: 50%;
	padding: 0 20px;
	margin-bottom: 20px;

	&__inner {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		padding: 15px;
		border: 0;
		border-radius: 0;
		background: transparent;
		text-decoration: none;
		text-align: left;
		opacity: .7;
		appearance: none;
		-webkit-appearance: none;
		transition: opacity .3s;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 2px solid rgba(#fff, .1);
			border-radius: 16px;
			background: rgba(#fff, .1);
			opacity: 0;
			transition: opacity .3s;
		}

		&:hover {
			opacity: 1;
		}

		&.js-feature-trigger {
			cursor: pointer;
		}

		&.active {
			opacity: 1;

			&:before {
				opacity: 1;
			}

			.feature__icon g {
				fill: #bae8e8;
			}
		}
	}

	&__icon {
		margin-bottom: 15px;
		width: 38px;
		height: 38px;

		g {
			transition: fill .3s;
		}
	}

	&__title {
		margin-bottom: 20px;
		font-family: $font-family-sans-serif;
		font-weight: 700;
		line-height: 1.25;
		text-transform: none;
	}

	&__entry {
		line-height: 1.2;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.feature {
		padding: 0 10px;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.feature {
		width: 33.3333%;
		margin-bottom: 10px;

		&__inner {
			padding: 10px;
		}

		&__icon {
			float: left;
			margin: 5px 10px 5px 0;
		}

		&__title {
			margin-bottom: 10px;
			font-size: 14px;
		}

		&__entry {
			font-size: 12px;
		}
	}
}

/* === Mobile Small === */
@include media-breakpoint-down(xs) {
	.feature {
		width: 50%;
	}
}
