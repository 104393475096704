/* ------------------------------------------------------------ *\
	Modal
\* ------------------------------------------------------------ */

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	padding: 20px;
	background: rgba(#2A2B30, .9);
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: opacity .3s .1s, visibility .3s .1s;

	&__dialog {
		flex: 1;
		transform: translateY(20px);
		width: 100%;
		max-width: 500px;
		padding: 50px 75px;
		background: #2A2B30;
		border: 2px solid rgba(#fff, .1);
		border-radius: 8px;
		text-align: center;
		opacity: 0;
		transition: transform .3s, opacity .3s;

		.active & {
			transform: translateY(0px);
			opacity: 1;
			transition: transform .3s .1s, opacity .3s .1s;
		}
	}

	&__close {
		position: absolute;
		top: 20px;
		right: 20px;
		background: transparent;
		border: 0;
		border-radius: 0;
		text-decoration: none;
		appearance: none;
		-webkit-appearance: none;
		transition: opacity .3s;

		&:hover {
			opacity: .7;
		}
	}

	&.active {
		opacity: 1 !important;
		visibility: visible !important;
		pointer-events: all;
		transition: opacity .3s, visibility .3s;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.modal {
		&__dialog {
			padding: 50px 25px;
		}
	}
}
