/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {
	position: relative;

	form {
		padding: 0;
	}

	&__title {
		margin-bottom: 30px;
		font-family: $font-family-sequel-wide;
		font-weight: 700;
		text-transform: none;
	}

	&__logo {
		margin-bottom: 30px;
	}

	&__row {
		margin-bottom: 25px;
	}

	&__label {
		display: block;
	}

	&__btn {
		padding-top: 11px;
		padding-bottom: 11px;
	}

	&__actions {
		&--gutter-large {
			margin-top: 50px;
		}
	}
}
