/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider {
	&__slide {
		height: auto;
	}

	&__slide__image {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
}
