/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	margin: 100px 0;

	&__head {
		max-width: 720px;
		margin: 0 auto 70px;
		text-align: center;
	}

	&__head__entry {
		font-size: 18px;
		font-weight: 400;
		line-height: 1.25;
	}

	&__title {
		margin-bottom: 20px;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.section {
		&__head {
			margin-bottom: 30px;
		}
		&__head__entry {
			font-size: 16px;
		}
	}
}
