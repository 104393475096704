/* ------------------------------------------------------------ *\
	Phone
\* ------------------------------------------------------------ */

.phone {
	/*  Contained in critical CSS in HEAD  */
	/*max-width: 428px;
	margin: auto;
	border-radius: 12%;*/

	/*  Contained in critical CSS in HEAD  */
	/*&__inner {
		position: relative;
		padding-top: 197%;
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url(../images/image-iphone-black-frame2.png);
			background-position: 0 0;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			pointer-events: none;
		}
	}*/

	/*  Contained in critical CSS in HEAD  */
	/*&__media {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 7.243% 7.4766% 7.0093% 7.7103%;
	}*/

	&--small {
		max-width: 342px;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.phone {
		max-width: 342px;
	}
}
