/* ------------------------------------------------------------ *\
	Hero
\* ------------------------------------------------------------ */

.hero {
	/*  Contained in critical CSS in HEAD  */
	/*margin: 100px 0;
	text-align: center;*/

	/*  Contained in critical CSS in HEAD  */
	/*&__container {
		padding-bottom: 40px;
	}*/

	&__media {
		/*  Contained in critical CSS in HEAD  */
		/*position: relative;
		z-index: 1;
		padding: 80px 0;

		video {
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
			min-width: 100%;
			min-height: 100%;
		}*/
	}
	&__media__actions {
		/*  Contained in critical CSS in HEAD  */
		/*margin-top: 20px;
		text-align: center;*/

		.btn {
			/*  Contained in critical CSS in HEAD  */
			/*opacity: 0;
			visibility: hidden;*/

			&.active {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__decoration {
		/*  Contained in critical CSS in HEAD  */
		/*position: absolute;
		top: 50%;
		left: 50%;
		z-index: -1;
		transform: translate(-50%, -50%);
		width: 100vw;
		margin-top: -50px;

		img {
			width: 100%;
		}*/
	}

	&__actions {
		display: none;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.hero {
		&__decoration {
			margin-top: 0;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.hero {
		margin-top: 60px;

		&__media {
			padding-top: 0;
			padding-bottom: 0;
		}

		&__actions {
			margin-top: 30px;
			display: block;
			text-align: center;
		}
	}
}
