/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article {
	margin: 100px 0;
	opacity: .8;

	ul {
		margin-bottom: 20px;
		list-style-type: circle;
		list-style-position: inside;
	}
	ol {
		margin-bottom: 20px;
		list-style-position: inside;
	}

	p {
		margin-bottom: 20px;
	}

	a {
		color: inherit;
		transition: color .3s;

		&:hover {
			color: $primary;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.article {
		margin: 100px 0;

		&__section {
			margin-bottom: 30px;
		}
	}
}
